import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import { useMessages } from "../context/MessageContext";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const Dropbox = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [subjectFilter, setSubjectFilter] = useState("");
  const [communityFilter, setCommunityFilter] = useState("newest");
  const [titleFilter, setTitleFilter] = useState("");
  const [dropbox, setDropbox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const limit = 10;
  const [classes, setClasses] = useState([]); // State for classes
  const [subjects, setSubjects] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const SubApiUrl = process.env.REACT_APP_API_NODE_URL;
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [classId, setClassId] = useState("");
  const [title, setTitle] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [teacher, setTeacher] = useState("");
  const SERVERIMGURL = "https://you-me-globaleducation.org/school/img";

  const handleSubjectChange = (event) => {
    const sessionData = JSON.parse(localStorage.getItem("student"));

    setSubjectFilter(event.target.value);
    setClassId(sessionData.class);
  };

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitleFilter(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("classid", studentData.class);
      formData.append("offset", 0);
      formData.append("limit", limit);
      formData.append("subject", subjectFilter);
      formData.append("title", titleFilter);
      formData.append("filter", communityFilter);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/dropboxdata.json`,
          requestOptions
        );
        const result = await response.json();
        setDropbox(result.dropdata);
        setTitle(result.title);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, classId, subjectFilter, titleFilter, communityFilter, limit]);

  // subject list api
  useEffect(() => {
    const getSubjects = async () => {
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${SubApiUrl}/subject/subjects?school_id=${studentData?.school_id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        console.log(result);
        setSubjects(result); // Assuming your API returns an array of subjects
      } catch (error) {
        console.error(error);
      }
    };

    getSubjects();
  }, [apiUrl]);

  // teacher list api
  useEffect(() => {
    const getTeachers = async () => {
      const studentData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/teacherlist.json?class_id=${studentData?.class}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        setTeacherList(result?.list); // Assuming your API returns an array of subjects
      } catch (error) {
        console.error(error);
      }
    };

    getTeachers();
  }, [apiUrl]);

  // class list api
  useEffect(() => {
    const getClasses = async () => {
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/classlist.json?school_id=${studentData?.school_id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        console.log(result);
        setClasses(result.classes); // Assuming your API returns an array of classes
      } catch (error) {
        console.error(error);
      }
    };

    getClasses();
  }, [apiUrl]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const clearInput1 = () => {
    setSubjectFilter("");
    setCommunityFilter("newest");
  };
  const clearInput = () => {
    setTitleFilter("");
  };
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="DropBox" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          <div className="content_card_title">
            Drop Box
            <div className="selectboxes">
              {subjectFilter !== "" && (
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <button className="back-btn" onClick={clearInput1}>
                    Clear
                  </button>
                  <span style={{ marginRight: "10px" }}></span>{" "}
                </div>
              )}
              <select
                className="form-control subj_s select2-hidden-accessible"
                id="subjfilter"
                onChange={handleSubjectChange}
                value={subjectFilter}
              >
                <option value="">Choose Subject</option>
                {subjects.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.subject_name}
                  </option>
                ))}
              </select>

              <select
                className="form-control community_filter select2-hidden-accessible"
                id="comm_filter"
                onChange={handleCommunityChange}
                value={communityFilter}
              >
                <option value="newest">Newest</option>
                <option value="pdf">PDF</option>
                <option value="video">Video</option>
                <option value="word">Word Document</option>
              </select>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="form-container">
            <div className="drobbox-body">
              <select
                className="form-control js-states chosetitle select2-hidden-accessible"
                id="title_filter"
                onChange={handleTitleChange}
                value={titleFilter}
              >
                <option value="">Choose Title</option>
                {title?.map((t) => (
                  <option key={t} value={t}>
                    {t}
                  </option>
                ))}
              </select>
              {titleFilter !== "" && (
                <div style={{ marginBottom: "20px" }}>
                  <button className="back-btn" onClick={clearInput}>
                    Clear
                  </button>
                  <span style={{ marginRight: "10px" }}></span>{" "}
                </div>
              )}
              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                // <p>Loading...</p>
                <div className="data-body">
                  <div
                    className="row col-sm-12"
                    id="viewcommunity"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {dropbox?.map((drop) => {
                      const teacherName = teacherList?.filter((e) => {
                        return e?.employee?.id == drop?.teacher_id;
                      });

                      return (
                        <div
                          className="col-sm-2 col_img"
                          key={drop.id}
                          style={{ marginBottom: "20px" }}
                        >
                          <Link
                            to={`/dashboard/dropbox/${drop.id}}`}
                            className="viewknow"
                          >
                            <img
                              src={`${SERVERIMGURL}/${drop.image}`}
                              alt="Dropbox Icon"
                            />
                            <div className="set_icon">
                              <i className="bi bi-filetype-pdf"></i>
                            </div>
                          </Link>
                          <p className="title" style={{ color: "#000" }}>
                            <b>Titre</b>: {drop.title} <br />
                            <b>Cours: </b>Langues nationales
                            <br />
                            <b>Teacher Name: </b>
                            {teacherName[0]?.employee?.f_name}{" "}
                            {teacherName[0]?.employee?.l_name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {!dropbox?.length ? <h3 style={{color: "gray", margin: "0"}}>Data Not Found</h3> : ""} */}
              <form
                method="post"
                acceptCharset="utf-8"
                action="/school/Dropbox/"
                style={{ display: "none" }}
              >
                <div>
                  <input type="hidden" name="_method" value="POST" />
                  <input
                    type="hidden"
                    name="_csrfToken"
                    autoComplete="off"
                    value="fd82b978dfb069d344e73b0d4e9dca020ee9d23fbca43988689186f03e216fc58422d41d5d040c943c9761c1bcb0be38cb915d43025ce94bebffabf8685a6482"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropbox;
