import React, { useState } from "react";
import "../css/style.css"; // Import your CSS file
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import ContentTitle from "../components/ContentTitle";

const IntensiveEnglish = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Academy" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          <div className="content_card_title flex-view">
            Intensive English
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="options2">
            <Link to="/dashboard/student-knowledge/intensive_english/kinder">
              <button className="option" data-target="grades">
                Kindergarten
              </button>
            </Link>
            <Link to="/dashboard/student-knowledge/intensive_english/primary">
              <button className="option" data-target="grades">
                Primary
              </button>
            </Link>
            <Link to="/dashboard/student-knowledge/intensive_english/highscl">
              <button className="option" data-target="subjects">
                High School
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntensiveEnglish;
