import React, { createContext, useContext, useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { StudentModal } from "../components/StudentModal";
import { ThreeDots } from "react-loader-spinner";
import { useMessages } from "../context/MessageContext";
import { SubjectContext } from "../context/SubjectIDContext";

const Subject = () => {
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  // const [isAscending, setIsAscending] = useState(true); // State to toggle sorting order
  const apiUrl = process.env.REACT_APP_API_MAIN_UR;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [studentModal, setStudentModal] = useState(false);
  const [sub, setSub] = useState("");
  const { showSuccess, showError, clearMessages } = useMessages();
  const { subjectId, setSubjectId } = useContext(SubjectContext);

  useEffect(() => {
    const getSubjects = async () => {
      setLoading(true);

      const language = localStorage.getItem("lang");
      const studentData = JSON.parse(localStorage.getItem("student"));
      const formDatas = new FormData();
      formDatas.append("student_id", studentData?.id);
      formDatas.append("class_id", studentData?.class);
      formDatas.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formDatas,
      };

      try {
        const REACT_APP_API_MAIN_URL =
          "https://you-me-globaleducation.org/school/api";
        const response = await fetch(
          `${REACT_APP_API_MAIN_URL}/subjectlist.json`,
          requestOptions
        );

        const result = await response.json();
        setSubjects(result?.subjects); // Assuming your API returns an array of subjects
        showSuccess(result?.message);
        if (subjectId) {
          setStudentModal(true);
          setSub(subjectId);
        } else {
          setStudentModal(false);
          setSubjectId("");
        }
      } catch (error) {
        console.error(error);
        showError(error?.status + error?.message);
      } finally {
        setLoading(false);
        setTimeout(() => {
          clearMessages();
        }, 3000);
      }
    };

    getSubjects();
  }, [apiUrl, subjectId]);

  console.log("sub", subjectId);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const studentDubjectModal = (subs) => {
    setSub(subs);
    setSubjectId(subs);
    setStudentModal(true);
  };

  const CloseModalFun = () => {
    setStudentModal(false);
    setSubjectId("");
  };

  // const toggleSortOrder = () => {
  //   setIsAscending(!isAscending);
  // };

  // const sortedSubjects = [...subjects].sort((a, b) => {
  //   return isAscending
  //     ? a.subject_name?.localeCompare(b.subject_name) // Ascending order
  //     : b.subject_name?.localeCompare(a.subject_name); // Descending order
  // });

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Student Subjects" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="content_card_title flex-view">
                StudentProfile - Subjects
                <button className="back-btn" onClick={goBack}>
                  Back
                </button>
              </div>
              <div className="form-container">
                <div style={{ marginLeft: "20px" }}>
                  {/* <button
                    className="sort-btn back-btn"
                    onClick={toggleSortOrder}
                  >
                    Sort {isAscending ? "Descending" : "Ascending"}
                  </button> */}
                </div>

                <div className="options">
                  {subjects?.map((sub) => (
                    <Link key={sub.id}>
                      <button
                        className="option"
                        data-target="grades"
                        onClick={() => studentDubjectModal(sub)}
                      >
                        {sub.subject_name}
                      </button>
                    </Link>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <StudentModal
        classtransform={`${studentModal ? "transformOn" : "transformOff"}`}
        closeModal={CloseModalFun}
        data={sub}
      />
    </div>
  );
};

export default Subject;
