// src/admin/Dashboard.js
import React, { useEffect, useState } from "react";
import "../css/style.css"; // Import your CSS file
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import ContentTitle from "../components/ContentTitle";
import { useMessages } from "../context/MessageContext";
import { useNavigate } from "react-router-dom";

const TutoringCenter = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [inputData, setInputData] = useState({
    username: "",
    password: "",
  });
  const [tutorid, setTutorid] = useState(null);
  const { showSuccess, showError, clearMessages } = useMessages();
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const language = localStorage.getItem("lang");
      const studentData = JSON.parse(localStorage.getItem("student"));
      const formDatas = new FormData();
      formDatas.append("student_id", studentData?.id);
      formDatas.append("session_id", studentData?.session_id);
      formDatas.append("class_id", studentData?.class);
      formDatas.append("school_id", studentData?.school_id);
      formDatas.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formDatas,
      };
      try {
        const response = await fetch(
          `${apiUrl}/tutoringcenter.json`,
          requestOptions
        );
        const result = await response.json();
        setData(result?.tutorsub_details);
      } catch (error) {
        console.log("file error", error);
      }
    };
    fetchData();
  }, [apiUrl]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleOpenModal = (id) => {
    setOpenModal(true);
    setTutorid(id);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setInputData({
      username: "",
      password: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const language = localStorage.getItem("lang");
    const studentData = JSON.parse(localStorage.getItem("student"));
    const formDatas = new FormData();
    formDatas.append("email", inputData?.username);
    formDatas.append("password", inputData?.password);
    formDatas.append("tutorid", tutorid);
    formDatas.append("school_id", studentData?.school_id);
    formDatas.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formDatas,
    };
    try {
      const response = await fetch(
        `${apiUrl}/tutoringlogin.json`,
        requestOptions
      );
      const result = await response.json();
      if (result?.data === "Invalid Email & Password") {
        setError(result?.data);
      }

      console.log(result?.status, 'result?.status')
      if (result?.status == 200) {
        navigate(
          `/dashboard/tutoring_center/content/${result?.data?.subject_id}/${result?.data?.teacher_id}/${result?.data?.class_id}`
        );
      } else {
        setTimeout(() => {
          handleCloseModal();
        }, 3000);
        setTimeout(() => {
          setError(error);
          clearMessages();
        }, 2000);
      }
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setError(error);
        clearMessages();
      }, 2000);
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <div id="main-content1" className="content_container">
          <ContentTitle name="Tutoring Center" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
          <div className="card_board">
            <div className="content_card_title flex-view">
              <span></span>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
            <div className="options">
              {data?.map((val) => {
                return (
                  <Link key={val?.id}>
                    <button
                      className="option"
                      data-target="grades"
                      onClick={() => handleOpenModal(val?.id)}
                    >
                      {val?.subjects_name} ({val?.grades_name})
                    </button>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${openModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box modal-announcement ${
            openModal ? "transformOn" : "transformOff"
          }`}
          style={{ maxWidth: "600px", minWidth: "250px" }}
        >
          <div className="custom-modal-title">
            Tutoring Center Login
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-container-width-label">
              <input
                placeholder="Username*"
                name="username"
                value={inputData.username}
                onChange={handleChange}
                required
              />
              <div className="input-container-width-label">
                <input
                  type="password"
                  placeholder="Password*"
                  name="password"
                  value={inputData.password}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            {error !== "" && (
              <p
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 5px",
                  margin: "0",
                }}
              >
                {error}
              </p>
            )}
            {/* btn-row */}
            <div className="btn-container">
              <button type="submit" className="back-btn">
                Login
              </button>
              <span
                style={{ marginRight: "10px", marginBottom: "10px" }}
              ></span>
              <button className="back-btn" onClick={handleCloseModal}>
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TutoringCenter;
