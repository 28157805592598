import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

const FoodHistory = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [invoiceSearchTerm, setInvoiceSearchTerm] = useState("");
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [originalData, setOriginalData] = useState([]); // Original fetched data
  const [originalInvoiceData, setOriginalInvoiceData] = useState([]); // Original fetched data
  const [invoiceData, setInvoiceData] = useState([]); // Original fetched data
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [amounts, setAmounts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalOrderDetail, setShowModalOrderDetail] = useState(false);
  const [orderInvoice, setOrderInvoice] = useState("");
  const [isAscending, setIsAscending] = useState(true); // State to toggle sorting orde
  const [sortKey, setSortKey] = useState(""); // Default sorting key
  const [isAscendingInvoice, setIsAscendingInvoice] = useState(true); // State to toggle sorting orde
  const [sortKeyInvoice, setSortKeyInvoice] = useState("order_no"); // Default sorting key
  const [datas, setDatas] = useState([]);
  const [orderFound, setOrderfound] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");
      const offset = (currentPage - 1) * limit;

      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);
      formData.append("student_id", sessionData?.id);
      formData.append("session_id", sessionData?.session_id);
      formData.append("offset", offset);
      formData.append("limit", limit);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/foodhistory.json`,
          requestOptions
        );
        const result = await response.json();
        setOriginalData(result?.cso_details);
        setHistory(result?.cso_details);
        setAmounts(result);
        setTotalPages(Math.ceil(result.total_cso / limit));
      } catch (error) {
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, currentPage, limit]);

  //   form data api
  const handliClick = async (event) => {
    event?.preventDefault();
    setLoading(true);
    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");
    const offset = (currentPage - 1) * limit;

    const formData = new FormData();
    formData.append("school_id", sessionData?.school_id);
    formData.append("student_id", sessionData?.id);
    formData.append("session_id", sessionData?.session_id);
    formData.append("startdate", startDate);
    formData.append("enddate", endDate);
    formData.append("offset", offset);
    formData.append("limit", limit);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(
        `${apiUrl}/foodhistory.json`,
        requestOptions
      );
      const result = await response.json();
      setOriginalData(result?.cso_details);
      setHistory(result?.cso_details);
      setAmounts(result);
      setTotalPages(Math.ceil(result?.total_cso / limit));
    } catch (error) {
      setError(error.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  // food history search
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    const filteredData = originalData?.filter(
      (item) =>
        (item?.food_item?.food_name?.toLowerCase() || "").includes(
          event.target.value.toLowerCase()
        ) ||
        (item?.order_no?.toLowerCase() || "").includes(
          event.target.value.toLowerCase()
        ) ||
        (item?.canteen_vendor?.f_name?.toLowerCase() || "").includes(
          event.target.value.toLowerCase()
        ) ||
        (item?.canteen_vendor?.l_name?.toLowerCase() || "").includes(
          event.target.value.toLowerCase()
        ) ||
        (item?.remark?.toLowerCase() || "").includes(
          event.target.value.toLowerCase()
        )
    );

    console.log(filteredData, "filteredData food history");
    setHistory(filteredData);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setOrderInvoice("");
  };

  const handleInvoiceClick = async (event) => {
    setOrderInvoice(event.target.value);
  };

  const openOrderDetailInfo = async (event) => {
    event.preventDefault();

    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");
    const offset = (currentPage - 1) * limit;

    const formData = new FormData();
    formData.append("order_no", orderInvoice);
    formData.append("student_id", sessionData?.id);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(`${apiUrl}/orderinfo.json`, requestOptions);
      const result = await response.json();
      setInvoiceData(result);
      setOriginalInvoiceData(result);
      setDatas(result?.data);
      setTotalPages2(Math.ceil(result?.total / limit));
      if (result?.data?.length > 0) {
        setShowModalOrderDetail(true);
      } else {
        setShowModalOrderDetail(false);
        setInvoiceData([]);
        setInvoiceSearchTerm("");
      }
      if (result?.data?.length == 0) {
        setOrderfound("No order found.");
      } else {
        setOrderfound("");
      }
    } catch (error) {
      setError(error.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseInvoiceModal = () => {
    setShowModalOrderDetail(false);
    setInvoiceSearchTerm("");
  };

  // invoice search data
  const handleInvoiceSearchChange = (event) => {
    const invoiceSearchTerm = event.target.value;
    setInvoiceSearchTerm(invoiceSearchTerm);
    setCurrentPage(1);

    const filteredData = originalInvoiceData?.data?.filter(
      (item) =>
        (item?.canteen_vendor?.vendor_company.toLowerCase() || "").includes(
          event.target.value.toLowerCase()
        ) ||
        (item?.food_item?.food_name?.toLowerCase() || "").includes(
          event.target.value.toLowerCase()
        ) ||
        item?.quantity ||
        item?.total_amount ||
        item?.food_amount ||
        item?.data?.o_status
    );

    console.log(filteredData, "filteredData");
    setHistory(filteredData);
  };

  const cancelOrder = async (id) => {
    const language = localStorage.getItem("lang");
    const formData = new FormData();
    formData.append("orderid", id);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(
        `${apiUrl}/cancelorder.json`,
        requestOptions
      );
    } catch (error) {
      setError(error.message || "Something went wrong");
    }
  };

  const toggleSortOrder = (key) => {
    console.log("k", key);
    if (sortKey === key) {
      setIsAscending(!isAscending);
    } else {
      setSortKey(key);
      setIsAscending(true);
    }
  };
  const sortedNotifications = React.useMemo(() => {
    return [...history].sort((a, b) => {
      console.log(a, b);

      if (sortKey === "date" || sortKey === "created_date") {
        const dateA = moment(a[sortKey], "DD-MM-YYYY", true);
        const dateB = moment(b[sortKey], "DD-MM-YYYY", true);

        if (!dateA.isValid() || !dateB.isValid()) return 0; // Skip invalid dates
        return isAscending ? dateA - dateB : dateB - dateA; // Ascending or Descending
      }

      // Handle numeric field sorting (e.g., quantity, price, total_price, status)
      if (
        ["quantity", "food_amount", "total_amount", "order_status"].includes(
          sortKey
        )
      ) {
        const valueA = a[sortKey] || 0;
        const valueB = b[sortKey] || 0;
        return isAscending ? valueA - valueB : valueB - valueA; // Numeric comparison
      }

      // Handle general string sorting (including nested fields)
      const compareNested = (key, aObj, bObj) => {
        return (aObj[key] || "").localeCompare(bObj[key] || "");
      };

      if (isAscending) {
        return (
          (a[sortKey] || "").localeCompare(b[sortKey] || "") ||
          compareNested(sortKey, a.food_item || {}, b.food_item || {}) ||
          compareNested(sortKey, a.canteen_vendor || {}, b.canteen_vendor || {})
        );
      } else {
        return (
          (b[sortKey] || "").localeCompare(a[sortKey] || "") ||
          compareNested(sortKey, b.food_item || {}, a.food_item || {}) ||
          compareNested(sortKey, b.canteen_vendor || {}, a.canteen_vendor || {})
        );
      }
    });
  }, [history, sortKey, isAscending]);

  const toggleSortOrderInvoice = (key) => {
    if (sortKeyInvoice === key) {
      setIsAscendingInvoice(!isAscendingInvoice);
    } else {
      setSortKeyInvoice(key);
      setIsAscendingInvoice(true);
    }
  };

  const sortedInvoice = [...datas].sort((a, b) => {
    let valA, valB;

    switch (sortKeyInvoice) {
      case "vendor_name":
        valA = a?.canteen_vendor?.vendor_company || "";
        valB = b?.canteen_vendor?.vendor_company || "";
        break;

      case "food_name":
        valA = a?.food_item?.food_name || "";
        valB = b?.food_item?.food_name || "";
        break;

      case "quantity":
        valA = a?.quantity || 0;
        valB = b?.quantity || 0;
        return isAscendingInvoice ? valA - valB : valB - valA; // Numeric sorting

      case "o_status":
        valA = a?.o_status || "";
        valB = b?.o_status || "";
        break;

      default:
        return 0;
    }

    return isAscendingInvoice
      ? valA.localeCompare(valB)
      : valB.localeCompare(valA);
  });

  console.log(invoiceData?.data?.length, "length");

  const handlePageChange2 = () => {};

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle
          name="Canteen"
          click={sidebarOpen}
          sidebarToggle={sidebarToggle}
        />
        <div className="card_board">
          <div className="content_card_title flex-view">
            My order History
            <div className="selectboxes">
              <button
                className="contact-us-btn"
                onClick={() => handleShowModal()}
              >
                Invoice Download With Order No
              </button>
              <span
                style={{ marginRight: "10px", marginBottom: "10px" }}
              ></span>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="amount-container">
            <p>Total Amount - ${amounts?.amtadded}</p>
            <p>Spent Amount - ${amounts?.amtspent}</p>
            <p>Balance Amount - ${amounts?.balance}</p>
          </div>
          <div className="row clearfix" style={{ padding: "0px 20px" }}>
            <div className="col-lg-12">
              <div className="card">
                <div className="date-time-input-wrapper">
                  <form onSubmit={handliClick}>
                    <div className="input-container">
                      <label>Start Date*</label>
                      <input
                        type="date"
                        required
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="input-container">
                      <label>End Date*</label>
                      <input
                        type="date"
                        required
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <button className="back-btn" type="submit">
                      Submit
                    </button>
                  </form>
                </div>
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting_asc"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-sort="ascending"
                              aria-label="activate to sort column descending"
                              style={{ width: "80px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("order_no")}
                              ></i>
                              Order No
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Topic: activate to sort column ascending"
                              style={{ width: "160px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("f_name")}
                              ></i>
                              Vendor Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="From: activate to sort column ascending"
                              style={{ width: "151px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("food_name")}
                              ></i>
                              Food Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Date: activate to sort column ascending"
                              style={{ width: "61.5156px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("quantity")}
                              ></i>
                              Quantity
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("food_amount")}
                              ></i>
                              Food Price
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("total_amount")}
                              ></i>
                              Total Price
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("order_status")}
                              ></i>
                              Order Status
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("remark")}
                              ></i>
                              Remarks(If any allergy)
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("date")}
                              ></i>
                              Delivery Date/Horaires
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("created_date")}
                              ></i>
                              Ordered Date/Horaires
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {history?.length == 0 && (
                            <tr>
                              <td colSpan="10" style={{ textAlign: "center" }}>
                                No data available
                              </td>
                            </tr>
                          )}
                          {sortedNotifications?.map((val) => {
                            const createDate = val?.created_date
                              ? new Date(
                                  Number(val.created_date) * 1000
                                ).toLocaleDateString()
                              : new Date().toLocaleDateString();
                            return (
                              <tr role="row" className="odd" key={val?.id}>
                                <td>{val?.order_no}</td>
                                <td>
                                  {val?.canteen_vendor.vendor_company} (
                                  {val?.canteen_vendor?.l_name} {""}
                                  {val?.canteen_vendor?.f_name} )
                                </td>
                                <td>{val?.food_item?.food_name}</td>
                                <td>{val?.quantity}</td>
                                <td>{`$${val?.food_amount}`}</td>
                                <td>{`$${val?.total_amount}`}</td>
                                <td
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {val?.order_status === 4 && (
                                    <button
                                      className="contact-btn"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "transparent",
                                        border: "1px solid rgb(26 107 207)",
                                      }}
                                    >
                                      Pending(marking undelivered)
                                    </button>
                                  )}
                                  {val?.order_status === 2 && (
                                    <button
                                      onClick={() => cancelOrder(val?.id)}
                                      className="contact-btn"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "red",
                                        border: "unset",
                                        color: "white",
                                      }}
                                    >
                                      Cancelled
                                    </button>
                                  )}
                                  {val?.order_status === 1 && (
                                    <button
                                      className="contact-btn"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "transparent",
                                        border: "unset",
                                      }}
                                    >
                                      Delivered
                                    </button>
                                  )}
                                </td>
                                <td>{val?.remark}</td>
                                <td>
                                  {moment(val?.date, "DD-MM-YYYY").format(
                                    "DD-MM-YYYY "
                                  )}
                                  ({val?.timings})
                                </td>
                                <td>
                                  {moment(val.created_date * 1000).format(
                                    "DD-MM-YYYY ( hh:mm A)"
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="pagination-container">
                    <p>
                      {/* Show 1 to {limit} of {notifications?.length} entries */}
                    </p>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Invoice Order Number modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${showModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "600px", minWidth: "250px" }}
        >
          <div className="custom-modal-title">
            Invoice Order Number
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <form onSubmit={openOrderDetailInfo}>
            <div className="input-container-width-label">
              <label>Enter order no.</label>
              <input
                type="text"
                placeholder="Enter order no"
                required
                onChange={handleInvoiceClick}
                value={orderInvoice}
              />

              {orderFound !== "" && (
                <p style={{ textAlign: "center" }}>{orderFound}</p>
              )}
            </div>
            {/* btn-row */}
            <div className="btn-container">
              <button type="submit" className="contact-us-btn">
                Click here to get detail of the order no.
              </button>
              <span
                style={{ marginRight: "10px", marginBottom: "10px" }}
              ></span>
              <button className="contact-us-btn" onClick={handleCloseModal}>
                Close
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Order Detailed Info modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModalOrderDetail ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${
            showModalOrderDetail ? "transformOn" : "transformOff"
          }`}
          style={{ maxWidth: "50%", minWidth: "250px" }}
        >
          <div className="custom-modal-title">
            Order Detailed Info
            <i
              className="bi bi-x-lg"
              onClick={handleCloseInvoiceModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <div className="invoice-container">
            <div className="text-container">
              <p>
                Student Name:{" "}
                <span>{invoiceData?.studentinfo?.["Student Name"]}</span>
              </p>
              <p>
                Student No:{" "}
                <span>{invoiceData?.studentinfo?.["Student No."]}</span>
              </p>
              <p>
                Order No: <span>{invoiceData?.studentinfo?.["Order No."]}</span>
              </p>
              <p>
                Date/Time :{" "}
                <span>{invoiceData?.studentinfo?.["Date/Time"]}</span>
              </p>
              <p>
                Class: <span>{invoiceData?.studentinfo?.Class}</span>
              </p>
              <p>
                School: <span>{invoiceData?.studentinfo?.School}</span>
              </p>
            </div>
            <Link
              to={`/dashboard/canteen/foodhistory/${orderInvoice}/downloadInvoicepdf`}
              style={{
                cursor: "pointer",
                background: "white",
                color: "gray",
                border: "1px solid gray",
                padding: "5px 8px",
              }}
              target="_blank"
            >
              <i className="bi bi-download"></i>
            </Link>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={invoiceSearchTerm}
                      onChange={handleInvoiceSearchChange}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting_asc"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-sort="ascending"
                              aria-label="activate to sort column descending"
                              style={{ width: "34px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  toggleSortOrderInvoice("vendor_company")
                                }
                              ></i>
                              Vendor Company
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Topic: activate to sort column ascending"
                              style={{ width: "30px" }}
                            >
                              Food Image
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="From: activate to sort column ascending"
                              style={{ width: "200px" }}
                            >
                              {" "}
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  toggleSortOrderInvoice("food_name")
                                }
                              ></i>
                              Food Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Date: activate to sort column ascending"
                              style={{ width: "61.5156px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  toggleSortOrderInvoice("quantity")
                                }
                              ></i>
                              Quantity
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              Food Price
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              Total Amount
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  toggleSortOrderInvoice("o_status")
                                }
                              ></i>
                              Order Status
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {sortedInvoice?.map((data) => (
                            <tr role="row" className="odd" key={data?.id}>
                              <td className="width45 sorting_1">
                                {data?.canteen_vendor?.vendor_company}
                              </td>
                              <td>
                                <img
                                  src={`https://you-me-globaleducation.org/school/c_food/${data?.food_item?.food_img}`}
                                  alt="food-image"
                                  style={{ height: "50px" }}
                                />
                              </td>
                              <td>{data?.food_item?.food_name}</td>
                              <td>{data?.quantity}</td>
                              <td>${data?.total_amount}</td>
                              <td>${data?.food_amount}</td>
                              <td>
                                <button
                                  onClick={() => handleShowModal(data)}
                                  className="contact-btn"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: `${
                                      data?.o_status === "Cancelled"
                                        ? "red"
                                        : data?.o_status === "Undelivered"
                                        ? "orange"
                                        : "#428000"
                                    }`,
                                    color: "white",
                                    border: "unset",
                                  }}
                                >
                                  {data?.o_status}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="pagination-container">
                    <p>
                      Show 1 to {limit} of {invoiceData?.data?.length} entries
                    </p>
                    <Pagination
                      currentPage={currentPage2}
                      totalPages={totalPages2}
                      onPageChange={handlePageChange2}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {invoiceData?.remark}
          <div className="btn-container">
            <button
              className="contact-us-btn"
              onClick={handleCloseInvoiceModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodHistory;
