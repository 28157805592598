import React, { useState } from "react";
import ContentTitle from "../components/ContentTitle";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";

const MySchool = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle
          name="Student Dashboard"
          click={sidebarOpen}
          sidebarToggle={sidebarToggle}
        />
        <div className="card_board">
          <div className="content_card_title flex-view">
            My School
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="options2">
            <Link to="/dashboard/events">
              <button className="option" data-target="grades">
                Events
              </button>
            </Link>
            <Link to="/dashboard/my_school/knowledge_center">
              <button className="option" data-target="grades">
                Knowledge Center
              </button>
            </Link>
            <Link to="/dashboard/my_school/code_of_conduct">
              <button className="option" data-target="subjects">
                Code Of Conduct
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySchool;
