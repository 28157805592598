// src/admin/Dashboard.js
import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useMessages } from "../context/MessageContext";
import ContentTitle from "../components/ContentTitle";
import { Link, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { ThreeDots } from "react-loader-spinner";

const ViewLibraryContent = () => {
  const [loading, setLoading] = useState(false);
  const [library, setLibrary] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [replyText, setReplyText] = useState("");
  const [comments, setComments] = useState([]);
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [dateArray, setDateArray] = useState([]);

  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const { showSuccess, showError, clearMessages } = useMessages();
  const [replyFormVisible, setReplyFormVisible] = useState({});
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  // library content api
  const getLibrary = async () => {
    setLoading(true);
    const lib_id = id;
    //  Convert number to string
    const numberString = lib_id.toString();
    // Create MD5 hash
    const md5Hash = CryptoJS.MD5(numberString).toString();

    try {
      const response = await fetch(
        `${apiUrl}/viewlibrarycontent.json?lib_id=${md5Hash}`
      );
      const result = await response.json();

      const commentsWithReplies = result?.comments_details.map((comment) => {
        return {
          ...comment,
          replies: result?.replies_details.filter(
            (reply) => reply.parent === comment.id
          ),
        };
      });
      setComments(commentsWithReplies);
      setLibrary(result.content_details);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // comment add api
  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    const studentData = await JSON.parse(localStorage.getItem("student"));
    //  Convert number to string
    const numberString = studentData?.id.toString();
    // Create MD5 hash
    const md5Hash = CryptoJS.MD5(numberString).toString();
    const language = localStorage.getItem("lang");

    const formData = new FormData();
    const lib_id = id;
    formData.append("studentid", md5Hash);
    formData.append("lib_id", lib_id);
    formData.append("comment_text", commentText);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(
        `${apiUrl}/postlibcomment.json`,
        requestOptions
      );
      const result = await response.json();
      if (response.ok) {
        setComments([...comments, result.new_comment]);
        setCommentText("");
        showSuccess("Comment added successfully");
        getLibrary();
      } else {
        showError(result.message || "Error adding comment");
      }
    } catch (error) {
      showError("Error adding comment");
    }
  };

  // reply add api
  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();

    const studentData = await JSON.parse(localStorage.getItem("student"));
    //  Convert number to string
    const numberString = studentData?.id.toString();
    // Create MD5 hash
    const md5Hash = CryptoJS.MD5(numberString).toString();
    const language = localStorage.getItem("lang");

    const formData = new FormData();
    const lib_id = id;
    formData.append("studentid", md5Hash);
    formData.append("lib_id", lib_id);
    formData.append("reply_text", replyText);
    formData.append("comment_id", commentId);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(
        `${apiUrl}/postlibreply.json`,
        requestOptions
      );

      const result = await response.json();
      if (response.ok) {
        const updatedComments = comments.map((comment) =>
          comment?.id === commentId
            ? { ...comment, replies: [...comment?.replies, result?.new_reply] }
            : comment
        );
        setComments(updatedComments);
        setReplyText("");
        setReplyFormVisible({});
        showSuccess("Reply added successfully");
        getLibrary();
      } else {
        showError(result.message || "Error adding reply");
      }
    } catch (error) {
      showError("Error adding reply");
    }
  };

  useEffect(() => {
    getLibrary();
  }, [apiUrl]);

  const handleReplyClick = (commentId) => {
    setReplyFormVisible((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />

      <div className={`main containerFull`}>
        <ContentTitle name="Library Content" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="content_card_title flex-view">
                {library.length > 0 && library[0].title}
                <button className="back-btn" onClick={goBack}>
                  Back
                </button>
              </div>
              <h4 style={{ marginTop: "0", marginLeft: "20px" }}>
                Description:{" "}
                <span style={{ fontWeight: "lighter" }}>
                  {library.length > 0 && library[0].description}
                </span>
              </h4>
              <div className="library-content-container">
                {Array.isArray(library[0]?.iframelink) &&
                  library[0]?.iframelink?.map((val, index) => {
                    return (
                      // <iframe
                      //   src={val}
                      //   title="iframe link"
                      //   key={index}
                      // ></iframe>
                      <img src={val} key={index} />
                    );
                  })}

                {!Array.isArray(library[0]?.iframelink) && (
                  <iframe
                    src={library[0]?.iframelink}
                    title="iframe link"
                  ></iframe>
                )}
              </div>
              <div className="comment-section-container">
                <div
                  className="content_card_title"
                  style={{ marginTop: "20px", padding: "0" }}
                >
                  Comments &amp; Reviews
                </div>
                <form
                  method="post"
                  encType="multipart/form-data"
                  acceptCharset="utf-8"
                  id="comment_form"
                  onSubmit={handleCommentSubmit}
                  style={{ padding: "0" }}
                >
                  <h4
                    style={{
                      margin: "0",
                      color: "rgb(75, 73, 73)",
                      fontSize: "22px",
                      fontWeight: "500",
                    }}
                  >
                    Post a comment:
                  </h4>
                  <div className="comment-container">
                    <div className="col-sm-12 clearfix ">
                      <div className="error" id="submitCommenterror"></div>
                      <div className="success" id="submitCommentsuccess"></div>
                    </div>
                    {/* <div className="col-sm-9" style={{ float: 'left' }}> */}
                    <textarea
                      name="comment_text"
                      id="comment_text"
                      className="form-control"
                      rows="2"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    ></textarea>
                    <input
                      type="hidden"
                      name="schoolid"
                      id="school_id"
                      value="41"
                    />
                    <input type="hidden" name="kid" id="kid" value="65" />
                    <input
                      type="hidden"
                      name="commentId"
                      id="commentId"
                      value="0"
                    />
                    <button
                      className="btn btn-primary btn-sm submit_comment library-coment-btn"
                      id="submit_comment"
                      style={{ cursor: "pointer" }}
                    >
                      Submit Comment
                    </button>
                  </div>
                </form>
                <div
                  className="content_card_title"
                  style={{
                    margin: "20px 0px",
                    padding: "20px",
                    borderBottom: "1px solid rgb(196, 194, 194)",
                  }}
                >
                  {comments.length} Comment(s)
                </div>
                <div className="comments-container">
                  <div id="comments-wrapper">
                    {comments?.map((comment) => {
                      return (
                        <div
                          className="comment clearfix"
                          key={comment?.id}
                          style={{ margin: "30px 0px" }}
                        >
                          <div className="comment-details">
                            <span className="comment-name">
                              <b>{comment?.user_name}</b> -{" "}
                              {comment?.created_date
                                ? new Date(
                                    comment?.created_date * 1000
                                  ).toLocaleDateString()
                                : new Date().toLocaleDateString()}
                            </span>

                            <p
                              style={{
                                marginTop: "5px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                color: "rgb(75, 73, 73)",
                              }}
                            >
                              {comment?.comments}
                            </p>
                            <Link
                              className="studentlib_reply-btn"
                              to="javascript:void(0)"
                              data-id={comment?.id}
                              onClick={() => handleReplyClick(comment?.id)}
                              style={{ cursor: "pointer" }}
                            >
                              Reply
                            </Link>
                          </div>
                          {replyFormVisible[comment?.id] && (
                            <form
                              className="reply_form clearfix"
                              id={`comment_reply_form_${comment?.id}`}
                              data-id={comment?.id}
                              onSubmit={(e) =>
                                handleReplySubmit(e, comment?.id)
                              }
                              style={{ padding: "0", margin: "10px 25px" }}
                            >
                              <div className="comment-container">
                                <textarea
                                  className="form-control"
                                  name="reply_text"
                                  id="reply_text"
                                  cols="30"
                                  rows="2"
                                  value={replyText}
                                  onChange={(e) => setReplyText(e.target.value)}
                                ></textarea>
                                <input
                                  type="hidden"
                                  name="r_kid"
                                  id="r_kid"
                                  value="65"
                                />
                                <input
                                  type="hidden"
                                  id="mdkid"
                                  value="fc490ca45c00b1249bbe3554a4fdf6fb"
                                />
                                <input
                                  type="hidden"
                                  name="skulid"
                                  id="sclid"
                                  value="41"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-xs pull-right submit-reply library-coment-btn"
                                  style={{ cursor: "pointer" }}
                                >
                                  Submit Reply
                                </button>
                              </div>
                            </form>
                          )}
                          <div className={`replies_wrapper_${comment?.id}`}>
                            {comment?.replies.map((reply) => (
                              <div
                                className="comment reply clearfix"
                                key={reply?.id}
                                style={{ marginBottom: "20px" }}
                              >
                                <div className="comment-details">
                                  <span className="comment-name reply">
                                    <b>{reply?.user_name}</b> -{" "}
                                  </span>
                                  <span className="comment-date">
                                    {reply?.created_date
                                      ? new Date(
                                          reply?.created_date * 1000
                                        ).toLocaleDateString()
                                      : new Date().toLocaleDateString()}{" "}
                                  </span>
                                  <p
                                    style={{
                                      margin: "5px 15px",
                                      fontSize: "14px",
                                    }}
                                    className="comment-date"
                                  >
                                    {reply?.comments}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewLibraryContent;
