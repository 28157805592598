import React from "react";
import { Link } from "react-router-dom";

export default function ContentTitle({ name, click, sidebarToggle }) {
  console.log(sidebarToggle, "sidebarToggle");
  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <h2 className="content_title">
          <Link to="" className="btn btn-xs btn-link btn-toggle-fullwidth">
            {sidebarToggle === false ? (
              <i class="bi bi-arrow-right" onClick={click}></i>
            ) : (
              <i className="bi bi-arrow-left" onClick={click}></i>
            )}
          </Link>
          {name}
        </h2>
      </div>
    </div>
  );
}
