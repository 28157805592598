import React, { useEffect, useState } from 'react';
import '../css/style.css'; // Import your CSS file
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Link } from 'react-router-dom';
import ContentTitle from "../components/ContentTitle";
import { useMessages } from '../context/MessageContext';
import { ThreeDots } from 'react-loader-spinner';

const YouMeAcademyCommunity = () => {
    const [sidebarToggle, setSidebarToggle] = useState(true);
    const { showSuccess, showError, clearMessages } = useMessages();
    const apiUrl = process.env.REACT_APP_API_MAIN_URL;
    const [loading, setLoading] = useState(false);
    const [knowledgeData, setKnowledgeData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const language = localStorage.getItem("lang");

            const requestOptions = {
              method: "GET",
            };
            try {
                const response = await fetch(`${apiUrl}/youmeknowledge.json?lang=${language}`, requestOptions);
                const result = await response.json();
                setKnowledgeData(result?.Knowledge_center);
            } catch (error) {
                showError(error?.message)
            } finally {
                setLoading(false)
                setTimeout(() => {
                    clearMessages();
                }, 3000)
            }
        }

        fetchData()

    }, [apiUrl])

    const sidebarOpen = () => {
        setSidebarToggle(!sidebarToggle);
    }

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="dashboard">
            <Header click={sidebarOpen} />
            <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
            <div className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}>
                <ContentTitle name="You-Me Academy" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
                <div className='card_board'>
                    <div className='content_card_title flex-view'>You-Me Knowledge Center<button className='back-btn' onClick={goBack}>Back</button></div>
                    <div className="options2">
                        {loading ? <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="#8e008e"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                            wrapperClass=""
                        /> : knowledgeData?.map((value, index) => {
                            if (value === 'High School') {
                                // Convert the string to lowercase
                                let lowerCaseStr = value.toLowerCase();
                                // Replace spaces with hyphens
                                let kebabCaseStr = lowerCaseStr.replace(/\s+/g, '-');

                                return <Link to={`/dashboard/student-knowledge/community/highscl`} key={index}>
                                    <button className="option" data-target="grades">{value}</button>
                                </Link>
                            } else {
                                return <Link to={`/dashboard/student-knowledge/community/${value?.toLowerCase()}`} key={index}>
                                    <button className="option" data-target="grades">{value}</button>
                                </Link>
                            }

                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default YouMeAcademyCommunity;
