// src/admin/StudentProfile.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";

const StudentProfile = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Student Profile" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          <div className="content_card_title flex-view">
            Profile
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="options">
            <Link to="/dashboard/subject">
              <button className="option" data-target="Subjects">
                Subjects
              </button>
            </Link>
            <Link to="/dashboard/library">
              <button className="option" data-target="Library">
                Library
              </button>
            </Link>
            <Link to="/dashboard/grades">
              <button className="option" data-target="Grades">
                Grades
              </button>
            </Link>
            <Link to="/dashboard/tutoring_center">
              <button className="option" data-target="Tutoring Center">
                Tutoring Center
              </button>
            </Link>
            <Link to="/dashboard/announcement">
              <button className="option" data-target="Announcement">
                Announcement
              </button>
            </Link>
            <Link to="/dashboard/calender">
              <button className="option" data-target="Announcement">
                Calendar
              </button>
            </Link>
            <Link to="/dashboard/attendance">
              <button className="option" data-target="Attendence">
                Attendance
              </button>
            </Link>
            <Link to="/dashboard/dropbox">
              <button className="option" data-target="DropBox">
                DropBox
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
