import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

const SchoolContacts = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [isAscending, setIsAscending] = useState(true); // State to toggle sorting orde
  const [sortKey, setSortKey] = useState("sender"); // Default sorting key

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const offset = (currentPage - 1) * limit;
      const formData = new FormData();
      formData.append("student_id", sessionData?.id);
      formData.append("school_id", sessionData?.school_id);
      formData.append("offset", offset);
      formData.append("limit", limit);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/contactschool.json`,
          requestOptions
        );
        const result = await response.json();
        setNotifications(result?.contact_details);
        setOriginalData(result?.contact_details);
        setTotalPages(Math.ceil(result.total_records / limit));
      } catch (error) {
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, currentPage, limit]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    const filteredData = originalData.filter(
      (item) =>
        item.classname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.sender?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.message?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.student_no?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    console.log(filteredData, originalData);
    setNotifications(filteredData);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleShowModal = (notification) => {
    setSelectedNotification(notification);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNotification(null);
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSortOrder = (key) => {
    if (sortKey === key) {
      // If the same key is clicked, toggle the sorting order
      setIsAscending(!isAscending);
    } else {
      // If a different key is clicked, set it as the sort key and reset to ascending
      setSortKey(key);
      setIsAscending(true);
    }
  };

  const sortedNotifications = [...notifications].sort((a, b) => {
    if (sortKey === "created_date") {
      // Handle date comparison
      const dateA = a[sortKey] ? moment(a[sortKey] * 1000) : null;
      const dateB = b[sortKey] ? moment(b[sortKey] * 1000) : null;
      console.log(dateA, dateB);
      if (!dateA.isValid() || !dateB.isValid()) return 0;

      return isAscending ? dateA - dateB : dateB - dateA;
    }
    return isAscending
      ? (a[sortKey] || "").localeCompare(b[sortKey] || "")
      : (b[sortKey] || "").localeCompare(a[sortKey] || "");
  });
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Contact School" click={sidebarOpen}  sidebarToggle={sidebarToggle} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            {" "}
            Contact School{" "}
            <div style={{ display: "flex" }}>
              <Link
                to="/dashboard/school_contacts/message/add"
                className="back-btn"
              >
                New Message
              </Link>
              <span style={{ margin: "0px 5px" }}></span>{" "}
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="row clearfix" style={{ padding: "0px 20px" }}>
            <div className="col-lg-12">
              <div className="card">
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting_asc"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-sort="ascending"
                              aria-label="activate to sort column descending"
                              style={{ width: "34px" }}
                            >
                              <label className="fancy-checkbox">
                                <input
                                  className="select-all"
                                  type="checkbox"
                                  name="checkbox"
                                />
                                <span></span>
                              </label>
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="From: activate to sort column ascending"
                              style={{ width: "685.312px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("sender")}
                              ></i>
                              From
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Message: activate to sort column ascending"
                              style={{ width: "260.812px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("message")}
                              ></i>
                              Message
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Time: activate to sort column ascending"
                              style={{ width: "210.453px" }}
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("created_date")}
                              ></i>
                              Time
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "109.422px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {sortedNotifications?.map((notification) => {
                            const timestamp = notification?.created_date * 1000; // Multiply by 1000 to convert seconds to milliseconds
                            const date = new Date(timestamp);

                            // Formatting options
                            const options = {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false,
                            };

                            // Format the date
                            const formattedDate =
                              date.toLocaleDateString("en-US", options) +
                              " " +
                              date.toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              });
                            return (
                              <tr
                                role="row"
                                className="odd"
                                key={notification?.id}
                              >
                                <td className="width45 sorting_1">
                                  <label className="fancy-checkbox">
                                    <input
                                      className="checkbox-tick"
                                      type="checkbox"
                                      name="checkbox"
                                      value={notification?.id}
                                    />
                                  </label>
                                </td>
                                <td>
                                  {notification?.sender}-{" "}
                                  {notification?.student_no} (class:{" "}
                                  {notification?.classname})
                                </td>
                                <td>{notification?.message}</td>
                                <td>{formattedDate}</td>
                                <td>
                                  <Link
                                    to={`/dashboard/school_contacts/message/view/${notification?.id}`}
                                    className="btn btn-sm btn-outline-secondary"
                                    style={{
                                      background: "transparent",
                                      border: "1px solid black",
                                      color: "black",
                                    }}
                                  >
                                    <i className="bi bi-eye"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="pagination-container">
                  <p>
                    Show 1 to {limit} of {notifications?.length} entries
                  </p>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${showModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "600px", minWidth: "250px" }}
        >
          <span className="custom-modal-close" onClick={handleCloseModal}>
            &times;
          </span>
          <h2>Announcement Details</h2>
          {selectedNotification && (
            <>
              <p>
                <strong>Title:</strong> {selectedNotification.title}
              </p>
              <p>
                <strong>Description:</strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedNotification.description,
                  }}
                />
              </p>
              <p>
                <strong>From:</strong> {selectedNotification.added_by}
              </p>
              <p>
                <strong>Date:</strong> {selectedNotification.schedule_date}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SchoolContacts;
