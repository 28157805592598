import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import CryptoJS from "crypto-js";
import { useMessages } from "../context/MessageContext";

const MentorshipView = () => {
  const { id } = useParams();
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [dropboxArray, setDropboxArray] = useState(null);
  const [loading, setLoading] = useState(false);
  const studentData = JSON.parse(localStorage.getItem("student"));
  const { showSuccess, showError, clearMessages } = useMessages();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const getDRopboxView = async () => {
      setLoading(true);
      //  Convert number to string
      const numberString = id.toString();
      // Create MD5 hash
      const md5Hash = CryptoJS.MD5(numberString).toString();
      const language = localStorage.getItem('lang');
      
      const formData = new FormData();
      formData.append("id", md5Hash);
      formData.append("school_id", studentData?.school_id);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/viewmentorship.json`,
          requestOptions
        );
        const result = await response.json();
        console.log(result, "result");
        setDropboxArray(result.knowledge_details[0]); // Assuming your API returns an array of subjects
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getDRopboxView();
  }, [apiUrl]);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />

      <div className={`main containerFull`}>
        <ContentTitle name="You-Me Academy" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="content_card_title flex-view">
                {dropboxArray?.title}
                <button className="back-btn" onClick={goBack}>
                  Back
                </button>
              </div>
              <h4 style={{ marginTop: "0", marginLeft: "20px" }}>
                Description:{" "}
                <span style={{ fontWeight: "lighter" }}>
                  {dropboxArray?.description}
                </span>
              </h4>
              <div className="library-content-container">
              {Array.isArray(dropboxArray?.iframelink) &&
                  dropboxArray?.iframelink?.map((val, index) => {
                    return (
                      // <iframe
                      //   src={val}
                      //   title="iframe link"
                      //   key={index}
                      // ></iframe>
                      <img src={val} key={index} />
                    );
                  })}

                {!Array.isArray(dropboxArray?.iframelink) && (
                  <iframe
                    src={dropboxArray?.iframelink}
                    title="iframe link"
                  ></iframe>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MentorshipView;
