import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import CryptoJS from "crypto-js";
import { useMessages } from "../context/MessageContext";
import moment from "moment";

const Discussions = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [dropboxArray, setDropboxArray] = useState(null);
  const [commentArray, setCommentArray] = useState([]);
  const [replyArray, setReplyArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredComments, setFilteredComments] = useState([]);
  const [replyFormVisible, setReplyFormVisible] = useState({});
  const [replyText, setReplyText] = useState("");
  const [commentText, setCommentText] = useState("");
  const studentData = JSON.parse(localStorage.getItem("student"));
  const { showSuccess, showError, clearMessages } = useMessages();
  const { id } = useParams();

  const handleReplyClick = (commentId) => {
    setReplyFormVisible({
      ...replyFormVisible,
      [commentId]: !replyFormVisible[commentId],
    });
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    // Clear previous messages

    clearMessages("");

    if (commentText.trim() === "") {
      showError("Comment cannot be empty");

      return;
    }

    const language = localStorage.getItem('lang');
    const formData = new FormData();
    formData.append("classid", studentData?.class);
    formData.append("schoolid", studentData?.school_id);
    formData.append("subjectid", id);
    formData.append("comment_text", commentText);
    formData.append("studentid", studentData?.id);
    formData.append("sessionid", "15");
    formData.append('lang', language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(
        `${apiUrl}/discussioncomment.json`,
        requestOptions
      );
      const result = await response.json();

      console.log("Comment submitted:", result);
      setTimeout(() => {
        showSuccess(result.message);
        setCommentText("");
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error(error);
      showError(error);
    }
  };

  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();
    const language = localStorage.getItem('lang');
    const formData = new FormData();
    formData.append("classid", studentData?.class);
    formData.append("schoolid", studentData?.school_id);
    formData.append("subjectid", id);
    formData.append("reply_text", replyText);
    formData.append("studentid", studentData?.id);
    formData.append("sessionid", studentData?.session_id);
    formData.append("comment_id", commentId);
    formData.append('lang', language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(
        `${apiUrl}/discussionreply.json`,
        requestOptions
      );
      const result = await response.json();

      console.log("Comment submitted:", result);
      setReplyText("");
      setReplyFormVisible({
        ...replyFormVisible,
        [commentId]: false,
      });
      setTimeout(() => {
        showSuccess(result.message);
        setCommentText("");
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error(error);
      showError(error);
    }
  };

  useEffect(() => {
    const getDRopboxView = async () => {
      setLoading(true);
      const language = localStorage.getItem('lang');
      const formData = new FormData();
      formData.append("classid", studentData?.class);
      formData.append("schoolid", studentData?.school_id);
      formData.append("subjectid", id);
      formData.append("studentid", studentData?.id);
      formData.append("sessionid", studentData?.session_id);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/studentdiscussion.json`,
          requestOptions
        );
        const result = await response.json();
        console.log(result, "result");
        setDropboxArray(result.subject_details); // Assuming your API returns an array of subjects
        setCommentArray(result.comments_details);
        setReplyArray(result.replies_details);

        const filtered = result.comments_details.map((comment) => {
          const replies = result.replies_details.filter(
            (reply) => reply.parent === comment.id
          );
          return { ...comment, replies };
        });

        setFilteredComments(filtered);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getDRopboxView();
  }, [apiUrl]);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Student Discussion" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="content_card_title flex-view">
                Subjects &gt; {dropboxArray?.subject_name} &gt; Discussion
                <button className="back-btn" onClick={goBack}>
                  Back
                </button>
              </div>

              <div className="comment-section-container">
                <div
                  className="content_card_title"
                  style={{ marginTop: "20px", padding: "0" }}
                >
                  Comments &amp; Reviews
                </div>
                <form
                  method="post"
                  encType="multipart/form-data"
                  acceptCharset="utf-8"
                  id="comment_form"
                  onSubmit={handleCommentSubmit}
                  style={{ padding: "0" }}
                >
                  <h4
                    style={{
                      margin: "0",
                      color: "rgb(75, 73, 73)",
                      fontSize: "22px",
                      fontWeight: "500",
                    }}
                  >
                    Post a comment:
                  </h4>
                  <div className="comment-container">
                    <div className="col-sm-12 clearfix ">
                      <div className="error" id="submitCommenterror"></div>
                      <div className="success" id="submitCommentsuccess"></div>
                    </div>
                    <textarea
                      name="comment_text"
                      id="comment_text"
                      className="form-control"
                      rows="2"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    ></textarea>
                    <input
                      type="hidden"
                      name="schoolid"
                      id="school_id"
                      value="41"
                    />
                    <input type="hidden" name="kid" id="kid" value="65" />
                    <input
                      type="hidden"
                      name="commentId"
                      id="commentId"
                      value="0"
                    />
                    <button
                      className="btn btn-primary btn-sm submit_comment library-coment-btn"
                      id="submit_comment"
                      style={{ cursor: "pointer" }}
                    >
                      Submit Comment
                    </button>
                  </div>
                </form>
                <div
                  className="content_card_title"
                  style={{
                    margin: "20px 0px",
                    padding: "20px",
                    borderBottom: "1px solid rgb(196, 194, 194)",
                  }}
                >
                  {`${commentArray.length} Comment(s)`}
                </div>
                <div className="comments-container">
                  <div id="comments-wrapper">
                    {commentArray?.map((comment) => {
                      // Filter replies for the current comment
                      const filteredReplies = replyArray.filter(
                        (reply) => reply.parent === comment.id
                      );

                      const createDate = comment?.created_date
                        ? new Date(
                            comment?.created_date * 1000
                          ).toLocaleDateString()
                        : new Date().toLocaleDateString();
                      console.log(createDate, "createDate");

                      return (
                        <div
                          className="comment clearfix"
                          key={comment?.id}
                          style={{ margin: "30px 0px" }}
                        >
                          <div className="comment-details">
                            <span className="comment-name">
                              <b>{comment?.user_name}</b> -{" "}
                              {moment(createDate, "MM-DD-YYYY").format(
                                "MMM DD, YYYY"
                              )}
                            </span>
                            <p
                              style={{
                                marginTop: "5px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                color: "rgb(75, 73, 73)",
                              }}
                            >
                              {comment?.comments}
                            </p>
                            <a
                              className="studentlib_reply-btn"
                              href="javascript:void(0)"
                              data-id={comment?.id}
                              onClick={() => handleReplyClick(comment?.id)}
                              style={{ cursor: "pointer" }}
                            >
                              Reply
                            </a>
                          </div>
                          {replyFormVisible[comment?.id] && (
                            <form
                              className="reply_form clearfix"
                              id={`comment_reply_form_${comment?.id}`}
                              data-id={comment?.id}
                              onSubmit={(e) =>
                                handleReplySubmit(e, comment?.id)
                              }
                              style={{ padding: "0", margin: "10px 25px" }}
                            >
                              <div className="comment-container">
                                <textarea
                                  className="form-control"
                                  name="reply_text"
                                  id="reply_text"
                                  cols="30"
                                  rows="2"
                                  value={replyText}
                                  onChange={(e) => setReplyText(e.target.value)}
                                ></textarea>
                                <input
                                  type="hidden"
                                  name="r_kid"
                                  id="r_kid"
                                  value="65"
                                />
                                <input
                                  type="hidden"
                                  id="mdkid"
                                  value="fc490ca45c00b1249bbe3554a4fdf6fb"
                                />
                                <input
                                  type="hidden"
                                  name="skulid"
                                  id="sclid"
                                  value="41"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-xs pull-right submit-reply library-coment-btn"
                                  style={{ cursor: "pointer" }}
                                >
                                  Submit Reply
                                </button>
                              </div>
                            </form>
                          )}
                          <div className={`replies_wrapper_${comment?.id}`}>
                            {filteredReplies.map((reply) => {
                              const createDate = reply?.created_date
                                ? new Date(
                                    reply?.created_date * 1000
                                  ).toLocaleDateString()
                                : new Date().toLocaleDateString();
                              console.log(createDate, "createDate");
                              return (
                                <div
                                  className="comment reply clearfix"
                                  key={reply?.id}
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div className="comment-details">
                                    <span className="comment-name reply">
                                      <b>{reply?.user_name}</b> -{" "}
                                    </span>
                                    <span className="comment-date">
                                      {moment(createDate, "MM-DD-YYYY").format(
                                        "MMM DD, YYYY"
                                      )}
                                    </span>
                                    <p
                                      style={{
                                        margin: "5px 15px",
                                        fontSize: "14px",
                                      }}
                                      className="comment-date"
                                    >
                                      {reply?.comments}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Discussions;
