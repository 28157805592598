import React, { useEffect, useState } from "react";
import ContentTitle from "../components/ContentTitle";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useParams } from "react-router-dom";

const MySchoolTabView = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const { tabname } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem('lang');
      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/codeofconduct.json`,
          requestOptions
        );
        const data = await response.json();
        setData(data?.codedetail);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [apiUrl]);

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="My School" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          <div className="content_card_title flex-view">
            Code Of Conduct
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>

          <h4 style={{ margin: "20px", color: "rgb(75, 73, 73)" }}>
            Title: <span style={{ fontWeight: "lighter" }}>{data?.title}</span>
          </h4>

          <h5 style={{ margin: "0px 20px", color: "rgb(75, 73, 73)" }}>
            Description -
            <p
              style={{
                fontWeight: "lighter",
                color: "gray",
                wordBreak: "break-all",
              }}
            >
              {data?.content}
            </p>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default MySchoolTabView;
