import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import CanteenSlider from "../components/Slider";
import { useNavigate } from "react-router-dom";

const Canteen = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [time, setTime] = useState([]);
  const [selectTime, setSelectTime] = useState("");
  const [date, setDate] = useState("");
  const [canteenData, setCanteenData] = useState([]);

  const navigate = useNavigate();

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  // time list api behalf of date
  useEffect(() => {
    const fetchTime = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);
      formData.append("class_id", sessionData?.class);
      formData.append("seldate", date);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/getcanteentime.json`,
          requestOptions
        );
        const data = await response.json();
        setTime(data?.timings);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchTime();
  }, [apiUrl, date]);

  // canteen data
  useEffect(() => {
    const fetchCanteens = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/canteendata.json`,
          requestOptions
        );
        const data = await response.json();
        setCanteenData(data);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCanteens();
  }, [apiUrl]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectTime !== "" && date !== "") {
      navigate(`/dashboard/canteen/featurevendor/${date}/${selectTime}`);
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />

      <div className={`main containerFull`}>
        <ContentTitle name="Canteen" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="content_card_title">
                <button
                  className="back-btn"
                  onClick={() => {
                    navigate("/dashboard/canteen/foodhistory");
                  }}
                >
                  My Order History
                </button>
                <div className="selectboxes">
                  <button
                    className="contact-us-btn"
                    onClick={() => navigate("/dashboard")}
                  >
                    Back To Dashboard
                  </button>
                  <span
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                  ></span>
                  <button className="back-btn" onClick={goBack}>
                    Back
                  </button>
                </div>
              </div>
              <CanteenSlider datass={canteenData}>
                {/* time-container on slider */}
                <div className="slider-time-wrapper">
                  <h1>Discover the best food in your school.</h1>
                  <div className="date-time-container">
                    <form onSubmit={handleSubmit}>
                      <div className="date">
                        <i className="bi bi-calendar-check"></i>
                        <input
                          type="date"
                          onChange={handleDateChange}
                          required
                        />
                      </div>
                      <div className="time">
                        <i className="bi bi-alarm"></i>
                        <select
                          onChange={(e) => setSelectTime(e.target.value)}
                          value={selectTime}
                          required
                        >
                          <option value="">Select The Time</option>
                          {time?.map((val) => {
                            return (
                              <option value={val.timings} key={val.timings}>
                                {val.timings}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <button type="submit">Continue</button>
                    </form>
                  </div>
                </div>
              </CanteenSlider>

              <div className="cantee-boxes-container">
                {canteenData?.vendor_details?.map((val, index) => {
                  return (
                    <div className="canteen-box" key={index}>
                      <img
                        src={`https://you-me-globaleducation.org/school/canteen/${val?.canteen_vendor?.logo}`}
                        alt="box-image"
                      />
                      <button className="canteen-btn">COMBO {index + 1}</button>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Canteen;
