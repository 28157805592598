import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import Header from "../components/Header";
import "../css/style.css";

const Calendar = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [events, setEvents] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [currentDate, setCurrentDate] = useState("");

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem('lang');
      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/calendardata.json`,
          requestOptions
        );
        const data = await response.json();
        if (data.calendardata && Array.isArray(data.calendardata)) {
          const formattedEvents = data?.calendardata.map((event) => {
            return {
              title: event.title, // Use 'title' from API response
              date: event.date,   // Ensure 'date' is in YYYY-MM-DD format
            };
          });
          console.log("Formatted Events:", formattedEvents); // Log formatted events
          setEvents(formattedEvents);
        } else {
          console.error("Unexpected data structure:", data);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [apiUrl]);

  useEffect(() => {
    // Format current date to YYYY-MM-DD
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleEventDidMount = (info) => {
    console.log("Event Did Mount Info:", info.event); // Log event info
    if (info.event.title) {
      info.el.style.backgroundColor = "#09A3F3";
      // info.el.style.color = "white";
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Calendar" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board calender">
          <div className="content_card_title flex-view">
            Student Profile &gt; Calendar
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="form-container">
            <div className="calender-container-main" style={{ width: "50%", minWidth: "300px",  margin: "0 auto", height: "500px" }}>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                editable={true}
                selectable={true}
                dateClick={(info) => alert(`Date clicked: ${info.dateStr}`)}
                eventDidMount={handleEventDidMount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
